import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import * as queryString from 'query-string';
import React, { useState } from 'react';
import './styles.scss';

const isOnPage = (location, pageSlug) => {
  const pageRegex = new RegExp(`\/${pageSlug}\/?$`);
  return pageRegex.test(location.pathname);
};

const GetYearsFromUrl = (location) => {
  const all = queryString.parse(location.search);
  var yearsArray = [];
  try {
    yearsArray = all.years.split(' ').map(Number);
  } catch (error) {
    yearsArray = [];
  }
  return yearsArray;
};

const addOrRemoveYear = (array, item) => {
  const exists = array.includes(item);
  if (exists) {
    return array.filter((c) => {
      return c !== item;
    });
  } else {
    const result = array;
    result.push(item);
    return result;
  }
};

const baseUrl = 'annual-challenge/finalists-mentors-judges';
const yearsInFilter = [
  2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012,
  2011, 2010, 2009,
];
const pagesInFilter = [
  { name: 'Winners', slug: `${baseUrl}/winners` },
  { name: 'Finalists', slug: `${baseUrl}/finalists` },
  { name: 'State Merit Winners', slug: `${baseUrl}/state-merit-winners` },
  { name: 'Honorable Mention', slug: `${baseUrl}/honorable-mentions` },
  { name: 'Improving Lives', slug: `${baseUrl}/improving-lives-winners` },
  { name: 'Mentors', slug: `${baseUrl}/mentors` },
  { name: 'Judges', slug: `${baseUrl}/judges` },
];

const InnovatorsFilter = ({ location }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const handleDropdownClick = () => setDropdownOpen((state) => !state);

  const HandleYearClick = (year, location) => {
    const yearsArray = GetYearsFromUrl(location);
    const newYearsArray = addOrRemoveYear(yearsArray, year);
    const path = newYearsArray[0]
      ? `${location.pathname}?years=${newYearsArray.join('+')}`
      : `${location.pathname}`;
    setDropdownOpen(false);
    navigate(path, {
      state: {
        disableScrollUpdate: true,
      },
    });
  };

  const GetFilterLabel = (location) => {
    const yearsArray = GetYearsFromUrl(location);
    if (yearsArray.length === 0) {
      return 'All';
    } else {
      if (yearsArray.length === 1) {
        return yearsArray[0].toString();
      } else {
        return `${yearsArray[0].toString()} (+${yearsArray.length - 1})`;
      }
    }
  };

  return (
    <div className="innovators-filter">
      <div className="innovators-filter__all">
        <div
          className="innovators-filter__all-main"
          onClick={() => handleDropdownClick()}
        >
          {GetFilterLabel(location)}
        </div>
        <div
          className={classNames(
            'innovators-filter__all-submenu',
            dropdownOpen && 'innovators-filter__all-submenu--open'
          )}
        >
          <ul>
            {yearsInFilter.map((year, i) => (
              <li
                key={i}
                onClick={() => HandleYearClick(year, location)}
                className={classNames(
                  GetYearsFromUrl(location).includes(year) && 'year-active'
                )}
              >
                {year}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {pagesInFilter.map((page, i) => (
        <Link
          key={i}
          state={{ disableScrollUpdate: true }}
          to={`/${page.slug}${location.search}`}
          className={classNames(
            'innovators-filter__link',
            `innovators-filter__link-${page.name
              .replace(' ', '-')
              .toLowerCase()}`,
            isOnPage(location, page.slug) && 'innovators-filter__link--active'
          )}
        >
          {page.name}
        </Link>
      ))}
    </div>
  );
};

export default InnovatorsFilter;
